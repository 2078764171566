import React, { useContext } from 'react';
import styled from 'styled-components';
import { Container } from '../styles/AppleStyledComponents';
import Hero from '../components/Hero';
import FileUpload from '../components/FileUpload';
import ProcessButton from '../components/ProcessButton';
import ResultsDisplay from '../components/ResultsDisplay';
import FileList from '../components/FileList';
import { AppContext } from '../context/AppContext';

const LandingPageContainer = styled(Container)`
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const DownloadButton = styled.button`
  background-color: #34c759;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2aa147;
  }

  &:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
  }
`;

const LandingPage = () => {
  const { downloadUrl } = useContext(AppContext);

  const handleDownload = () => {
    if (downloadUrl) {
      const fullUrl = new URL(downloadUrl, process.env.REACT_APP_API_URL).href;
      window.open(fullUrl, '_blank');
    }
  };

  return (
    <>
      <Hero />
      <LandingPageContainer>
        <FileUpload />
        <FileList />
        <ButtonContainer>
          <ProcessButton />
          {downloadUrl && (
            <DownloadButton onClick={handleDownload}>
              Download CSV
            </DownloadButton>
          )}
        </ButtonContainer>
        <ResultsDisplay />
      </LandingPageContainer>
    </>
  );
};

export default LandingPage;