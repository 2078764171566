import styled from 'styled-components';

export const colors = {
  background: '#ffffff',
  text: '#1d1d1f',
  accent: '#0071e3',
  secondary: '#86868b',
  tertiary: '#f5f5f7',
};

export const typography = {
  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  h1: {
    fontSize: '56px',
    fontWeight: '600',
    letterSpacing: '-0.005em',
    lineHeight: '1.07143',
  },
  h2: {
    fontSize: '28px',
    fontWeight: '600',
    letterSpacing: '.007em',
    lineHeight: '1.10722',
  },
  body: {
    fontSize: '17px',
    lineHeight: '1.47059',
    fontWeight: '400',
    letterSpacing: '-.022em',
  },
};

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const Hero = styled.div`
  background-color: ${colors.background};
  padding: 80px 0;
  text-align: center;
`;

export const HeroTitle = styled.h1`
  font-size: 48px;
  font-weight: 600;
  color: ${colors.text};
  margin-bottom: 20px;
`;

export const HeroSubtitle = styled.p`
  font-size: 24px;
  color: ${colors.text};
  margin-bottom: 40px;
`;

export const Button = styled.button`
  background-color: ${colors.accent};
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
`;

export const UploadArea = styled.div`
  border: 2px dashed ${colors.accent};
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  margin-top: 40px;
`;

export const UploadText = styled.p`
  font-size: 18px;
  color: ${colors.text};
  margin-top: 20px;
`;