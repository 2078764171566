import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/AppContext';
import CircularProgress from './CircularProgress';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled.button`
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
  }
`;

const ProcessButton = () => {
  const { files, processFiles, isProcessing } = useContext(AppContext);

  const handleClick = () => {
    if (files.length > 0) {
      processFiles(files);
    }
  };

  return (
    <ButtonContainer>
      <StyledButton onClick={handleClick} disabled={files.length === 0 || isProcessing}>
        {isProcessing ? 'Processing...' : 'Process Files'}
      </StyledButton>
      {isProcessing && <CircularProgress />}
    </ButtonContainer>
  );
};

export default ProcessButton;