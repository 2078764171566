import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../styles/AppleStyledComponents';

const TopBar = styled.header`
  background-color: ${colors.background};
  padding: 0;
  border-bottom: 1px solid #e0e0e0;
`;

const TopBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 22px;
  height: 44px;
`;

const Logo = styled(Link)`
  color: ${colors.text};
  text-decoration: none;
  font-weight: bold;
  font-size: 21px;
`;

const NavMenu = styled.nav`
  display: flex;
`;

const NavItem = styled.div`
  margin-left: 20px;
`;

const NavLink = styled(Link)`
  color: ${colors.text};
  text-decoration: none;
  font-size: 12px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  
  &:hover {
    opacity: 1;
  }
`;

const AppleStyleTopBar = () => {
  return (
    <TopBar>
      <TopBarContent>
        <Logo to="/">AssetReader</Logo>
        <NavMenu>
          <NavItem><NavLink to="/">Home</NavLink></NavItem>
          <NavItem><NavLink to="/features">Features</NavLink></NavItem>
          <NavItem><NavLink to="/pricing">Pricing</NavLink></NavItem>
        </NavMenu>
      </TopBarContent>
    </TopBar>
  );
};

export default AppleStyleTopBar;