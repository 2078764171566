import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/AppContext';
import { FaTimes } from 'react-icons/fa';

const FileListContainer = styled.div`
  max-width: 600px;
  margin: 0 auto calc(var(--spacing-unit) * var(--golden-ratio) * 2);
`;

const FileItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(var(--spacing-unit) * var(--golden-ratio) / 2);
  border-bottom: 1px solid var(--light-gray);
  margin-bottom: 15px; // Add more space between items

  &:last-child {
    border-bottom: none;
  }
`;

const FileName = styled.span`
  font-size: 14px;
  color: var(--text-color);
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #8B0000; // Dark red color
  cursor: pointer;
  font-size: 18px;
  padding: 0;
  display: flex;
  align-items: center;
`;

function FileList() {
  const { files, setFiles } = useContext(AppContext);

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  return (
    <FileListContainer>
      {files.map((file, index) => (
        <FileItem key={index}>
          <FileName>{file.name}</FileName>
          <RemoveButton onClick={() => removeFile(index)}>
            <FaTimes />
          </RemoveButton>
        </FileItem>
      ))}
    </FileListContainer>
  );
}

export default FileList;