import React, { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { AppContext } from '../context/AppContext';
import axios from 'axios';

const UploadArea = styled.div`
  border: 2px dashed #007aff;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
`;

const UploadText = styled.p`
  font-size: 16px;
  color: #1d1d1f;
`;

const FileList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FileItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #8B0000; /* Dark red color */
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: #ff3b30;
  font-size: 14px;
  margin-top: 10px;
`;

const FileUpload = () => {
  const { setFiles, setError } = useContext(AppContext);

  const onDrop = useCallback((acceptedFiles) => {
    console.log('Files dropped:', acceptedFiles);
    const pdfFiles = acceptedFiles.filter(file => file.type === 'application/pdf');
    console.log('PDF files:', pdfFiles);
    if (pdfFiles.length < acceptedFiles.length) {
      setError('Please upload PDF files only.');
      return;
    }
    
    setFiles(prevFiles => [...prevFiles, ...pdfFiles]);
  }, [setFiles, setError]);

  const { getRootProps, getInputProps } = useDropzone({ 
    onDrop,
    accept: {'application/pdf': ['.pdf']},
    onDropRejected: () => setError('Please upload PDF files only.')
  });

  return (
    <div>
      <UploadArea {...getRootProps()}>
        <input {...getInputProps()} />
        <UploadText>
          Drag 'n' drop PDF files here, or click to select files
        </UploadText>
      </UploadArea>
    </div>
  );
};

export default FileUpload;