import React from 'react';

function SupportPage() {
  return (
    <div>
      <h1>Support Page</h1>
      <p>This is the support page content.</p>
    </div>
  );
}

export default SupportPage;