import { createGlobalStyle } from 'styled-components';
import { typography, colors } from './AppleStyledComponents';

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: ${typography.fontFamily};
    color: ${colors.text};
    background-color: ${colors.background};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;