import React from 'react';
import styled from 'styled-components';
import { Container, colors, typography } from '../styles/AppleStyledComponents';

const FeaturesContainer = styled(Container)`
  padding: 120px 0;
  text-align: center;
`;

const Title = styled.h1`
  ${typography.h1}
  color: ${colors.text};
  margin-bottom: 60px;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-top: 80px;
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeatureIcon = styled.svg`
  width: 64px;
  height: 64px;
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h2`
  ${typography.h2}
  color: ${colors.text};
  margin-bottom: 16px;
`;

const FeatureDescription = styled.p`
  ${typography.body}
  color: ${colors.secondary};
  max-width: 280px;
  margin: 0 auto;
`;

const FeaturesPage = () => {
  return (
    <FeaturesContainer>
      <Title>AssetReader Features</Title>
      <FeatureGrid>
        <FeatureItem>
          <FeatureIcon viewBox="0 0 64 64">
            <path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30 30-13.432 30-30S48.568 2 32 2zm0 54c-13.233 0-24-10.767-24-24S18.767 8 32 8s24 10.767 24 24-10.767 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16zm0 28c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12zm0-20c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" fill={colors.accent}/>
          </FeatureIcon>
          <FeatureTitle>Accuracy</FeatureTitle>
          <FeatureDescription>
            AI-powered analysis ensures precise extraction of key loan terms, minimizing errors and enhancing reliability.
          </FeatureDescription>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon viewBox="0 0 64 64">
            <path d="M58.999 32H53l-9-15.001L35 32h-6l-9-15.001L11 32H5.001c-1.104 0-2 .896-2 2 0 1.105.896 2 2 2H11l9 15.001L29 36h6l9 15.001L53 36h5.999c1.104 0 2-.895 2-2 0-1.104-.896-2-2-2zM20 49.001L11 34h18l-9 15.001zM44 49.001L35 34h18l-9 15.001z" fill={colors.accent}/>
          </FeatureIcon>
          <FeatureTitle>Speed</FeatureTitle>
          <FeatureDescription>
            Process complex loan documents in seconds, saving hours of manual review and accelerating your workflow.
          </FeatureDescription>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon viewBox="0 0 64 64">
            <path d="M52 24h-4v-8c0-8.836-7.164-16-16-16S16 7.164 16 16v8h-4c-2.211 0-4 1.789-4 4v32c0 2.211 1.789 4 4 4h40c2.211 0 4-1.789 4-4V28c0-2.211-1.789-4-4-4zM20 16c0-6.627 5.373-12 12-12s12 5.373 12 12v8H20v-8zm32 44H12V28h40v32zm-20-12c2.211 0 4-1.789 4-4s-1.789-4-4-4-4 1.789-4 4 1.789 4 4 4z" fill={colors.accent}/>
          </FeatureIcon>
          <FeatureTitle>Security</FeatureTitle>
          <FeatureDescription>
            Encryption and secure data handling ensure your sensitive financial information remains protected.
          </FeatureDescription>
        </FeatureItem>
      </FeatureGrid>
    </FeaturesContainer>
  );
};

export default FeaturesPage;