import React from 'react';
import styled from 'styled-components';
import { colors, typography } from '../styles/AppleStyledComponents';

const PricingContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  ${typography.h1}
  color: ${colors.text};
  margin-bottom: 60px;
  text-align: center;
`;

const PlanContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
`;

const PlanCard = styled.div`
  background-color: ${colors.tertiary};
  border-radius: 18px;
  padding: 40px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }
`;

const PlanName = styled.h3`
  ${typography.h2}
  color: ${colors.text};
  margin-bottom: 16px;
`;

const PlanPrice = styled.div`
  font-size: 48px;
  font-weight: 600;
  color: ${colors.accent};
  margin-bottom: 24px;
`;

const PlanFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 32px;
  flex-grow: 1;
`;

const PlanFeature = styled.li`
  ${typography.body}
  color: ${colors.secondary};
  margin-bottom: 12px;
`;

const PlanButton = styled.button`
  background-color: ${colors.accent};
  color: ${colors.background};
  border: none;
  border-radius: 980px;
  padding: 12px 24px;
  font-size: 17px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.text};
  }
`;

function PricingSection() {
  return (
    <PricingContainer>
      <Title>Enterprise Solution</Title>
      <PlanContainer>
        <PlanCard>
          <PlanName>Enterprise</PlanName>
          <PlanPrice>Custom</PlanPrice>
          <PlanFeatures>
            <PlanFeature>Full API access</PlanFeature>
            <PlanFeature>24/7 phone & email support</PlanFeature>
            <PlanFeature>Custom integrations</PlanFeature>
          </PlanFeatures>
          <PlanButton>Contact Sales</PlanButton>
        </PlanCard>
      </PlanContainer>
    </PricingContainer>
  );
}

export default PricingSection;